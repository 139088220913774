import { navigate } from 'gatsby';

const IndexPage = () => {
  if (typeof window !== "undefined") {
    navigate("/o/");
  }
  return <></>;
};

export default IndexPage;
